import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { ISwaSwtCircuit } from '../interfaces/ISwaSwtCircuit';
import { Grid, TextField, Button, Box } from '@mui/material';
import { CvlanInput, IPV4MaskInput, Ipv4CidrRegex, Ipv4MultilineRegex, PortaInput, PrefixInput } from '../utils/MaskUtils';
import FormGroups from './FormGroups';

type Props = {
  installation?: ISwaSwtCircuit,
  onSave: (installation: ISwaSwtCircuit) => void;
};

const SwaSwtCircuitForm = ({ installation, onSave }: Props) => {
  const initialFormState = {
    id: installation?.id || '',
    cliente: installation?.cliente || '',
    cvlan: installation?.cvlan || '',
    bandwidth: installation?.bandwidth || '',

    hostnameSwt: installation?.hostnameSwt || '',
    ipGerenciaSwt: installation?.ipGerenciaSwt || '',

    hostnameSwa: installation?.hostnameSwa || '',
    portaSwa: installation?.portaSwa || '',
    ipGerenciaSwa: installation?.ipGerenciaSwa || '',

    ipGerenciaGateway: installation?.ipGerenciaGateway || '',
    prefixRedeGerencia: installation?.prefixRedeGerencia || '',
    vlanGerencia: installation?.vlanGerencia || '',
  };

  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});

  const setInputRef = (name: string) => (element: HTMLInputElement | null) => {
    inputRefs.current[name] = element;
  };

  const handleScrollIntoView = (fieldName: string) => {
    const inputRef = inputRefs.current[fieldName];
    if (inputRef) {
      inputRef.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });;
    } else {
      console.error(`Não foi possível encontrar o campo: ${fieldName}`);
    }
  };

  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState<{ [key: string]: string; }>({});

  const validateField = (name: string, value: any) => {
    let error = '';

    const validators: { [key: string]: (value: any) => boolean; } = {
      ipGerenciaSwt: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipGerenciaGateway: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipGerenciaSwa: value => value !== '' ? Ipv4CidrRegex.test(value) : true,

    };

    if (validators[name] && !validators[name](value)) {
      error = 'Formato inválido';
    }

    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    return error === '';
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const formFields = { ...formState };

    let isValid = true;
    for (const [name, value] of Object.entries(formFields)) {
      if (!validateField(name, value)) {
        isValid = false;
        handleScrollIntoView(name);
      }
    }
    if (isValid) {
      onSave({
        id: installation?.id || formFields.id,
        cliente: formFields.cliente,
        cvlan: formFields.cvlan !== '' ? Number(formFields.cvlan) : undefined,
        bandwidth: formFields.bandwidth !== '' ? Number(formFields.bandwidth) : undefined,

        hostnameSwt: formFields.hostnameSwt,
        ipGerenciaSwt: formFields.ipGerenciaSwt !== '' ? formFields.ipGerenciaSwt : undefined,

        hostnameSwa: formFields.hostnameSwa,
        portaSwa: formFields.portaSwa,
        ipGerenciaSwa: formFields.ipGerenciaSwa !== '' ? formFields.ipGerenciaSwa : undefined,

        ipGerenciaGateway: formFields.ipGerenciaGateway !== '' ? formFields.ipGerenciaGateway : undefined,
        prefixRedeGerencia: formFields.prefixRedeGerencia !== '' ? Number(formFields.prefixRedeGerencia) : undefined,
        vlanGerencia: formFields.vlanGerencia !== '' ? Number(formFields.vlanGerencia) : undefined,
      });
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit} paddingTop={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name='id'
            label="ID Vantive"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            value={formState.id}
            onChange={handleChange}
            fullWidth
            required
            disabled={!!installation}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='cliente'
            label="Cliente"
            value={formState.cliente}
            onChange={handleChange}
            inputProps={{
              maxLength: 512
            }}
            helperText={`${formState.cliente.length}/512 caracteres`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='cvlan'
            label="CVLAN"
            InputProps={{
              inputComponent: CvlanInput as any
            }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            inputRef={setInputRef('cvlan')}
            value={String(formState.cvlan)}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='bandwidth'
            label="BANDWIDTH (Mbps)"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            inputRef={setInputRef('bandwidth')}
            value={formState.bandwidth}
            onChange={handleChange}
            fullWidth

          />
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='SWT'>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name='hostnameSwt'
                  label="Hostname"
                  value={formState.hostnameSwt}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipGerenciaSwt'
                  label="IP Gerencia SWT"
                  inputRef={setInputRef('ipGerenciaSwt')}
                  value={formState.ipGerenciaSwt}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipGerenciaSwt}
                  helperText={errors.ipGerenciaSwt}
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='SWA'>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name='hostnameSwa'
                  label="Hostname"
                  value={formState.hostnameSwa}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='portaSwa'
                  label="Porta"
                  InputProps={{
                    inputComponent: PortaInput as any
                  }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  inputRef={setInputRef('portaSwa')}
                  value={formState.portaSwa}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipGerenciaSwa'
                  label="IP Gerencia SWA"
                  inputRef={setInputRef('ipGerenciaSwa')}
                  value={formState.ipGerenciaSwa}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipGerenciaSwa}
                  helperText={errors.ipGerenciaSwa}
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='ipGerenciaGateway'
            label="IP Gerencia Gateway"
            inputRef={setInputRef('ipGerenciaGateway')}
            value={formState.ipGerenciaGateway}
            onChange={handleChange}
            fullWidth
            InputProps={{
              inputComponent: IPV4MaskInput as any
            }}
            error={!!errors.ipGerenciaGateway}
            helperText={errors.ipGerenciaGateway}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='prefixRedeGerencia'
            label="Prefixo Rede Gerencia"
            InputProps={{
              inputComponent: PrefixInput as any
            }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            inputRef={setInputRef('prefixRedeGerencia')}
            value={String(formState.prefixRedeGerencia)}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='vlanGerencia'
            label="Vlan Gerencia"
            InputProps={{
              inputComponent: CvlanInput as any
            }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            inputRef={setInputRef('vlanGerencia')}
            value={String(formState.vlanGerencia)}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button className='button' type="submit" variant="contained">
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SwaSwtCircuitForm;