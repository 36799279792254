import { Button, Skeleton, Autocomplete, TextField, Box } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import handleError from '../../utils/handleError';
import { certificationsRequest } from '../../services/apiSys';
import { Certification } from '../../interfaces/Certification';
import CertificationsDataGrid from './CertificationsDataGrid';

const Certifications = () => {
  const [idVantive, setIdVantive] = useState<string>('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [certifications, setCertifications] = useState<Certification[]>();

  useEffect(() => {
    // Load search history from localStorage when the component mounts
    const storedHistory = localStorage.getItem('iatVantiveIdSearchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const saveSearchHistory = (history: string[]) => {
    // Save search history to localStorage
    localStorage.setItem('iatVantiveIdSearchHistory', JSON.stringify(history));
  };

  const handleSearchVantiveId = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      setError("");
      setCertifications(undefined);

      if (idVantive.trim() !== '' && !searchHistory.includes(idVantive)) {
        // Add the search input to the history
        const updatedHistory = [idVantive, ...searchHistory.slice(0, 9)];
        setSearchHistory(updatedHistory);
        // Save the updated history to localStorage
        saveSearchHistory(updatedHistory);
      }

      const resp = await certificationsRequest(idVantive);
      setCertifications(resp.data);
    } catch (error: any) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className='main'>
      <h3>Consultar Certificações</h3>
      <Box
        component="form"
        onSubmit={handleSearchVantiveId}
        display={'flex'}
        width={'100%'}
      >
        <Autocomplete
          freeSolo
          options={searchHistory}
          fullWidth
          value={idVantive}
          onInputChange={(e, value) => setIdVantive(value)}
          renderInput={(params) => <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: 'query-input',
              type: 'number'
            }}
            label="Vantive ID"
            required
          />
          }
        />
        <Button
          className='button'
          sx={{ marginLeft: '1em' }}
          variant="contained"
          color="primary"
          // disabled={loading}
          type='submit'
        >
          Buscar
        </Button>
      </Box>
      {certifications && (
        <CertificationsDataGrid certifications={certifications} />
      )}
      {error && <p className='error'>{error}</p>}
      {loading &&
        <Skeleton variant='rounded' width='100%' height={500} />
      }
    </div>
  );
};

export default Certifications;