import React from "react";
import { IMask, IMaskInput } from "react-imask";

type CustomInput = {
  name?: string,
  onChange: (event: { target: { value: string; name?: string; }; }) => void;
};

export const Ipv4CidrRegex = new RegExp(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/);
export const Ipv6CidrRegex = new RegExp(/^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/);
export const Ipv4MultilineRegex = new RegExp(/^((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[\r\n]+)*(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/);

export const IPV4MaskInput = React.forwardRef<HTMLInputElement, CustomInput>((props, ref) => {
  const { name, onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask="IP.IP.IP.IP"
      blocks={{
        IP: {
          mask: Number,
          min: 0,
          max: 255,
          scale: 0,
        }
      }}
      placeholder='Ex: 192.168.0.1'
      onAccept={(value) => onChange({ target: { value, name } })}
    // unmask={true}
    />
  );
});

export const IPV4CIDRMaskInput = React.forwardRef<HTMLInputElement, CustomInput>((props, ref) => {
  const { name, onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask="IP.IP.IP.IP[/MM]"
      blocks={{
        IP: {
          mask: Number,
          min: 0,
          max: 255,
          scale: 0,
        },
        'MM': {
          mask: IMask.MaskedRange,
          from: 1,
          to: 32
        },
      }}
      placeholder='Ex: 192.168.0.1/12'
      onAccept={(value) => onChange({ target: { name, value } })}
    />
  );
});

export const TextIPV6MaskInput = React.forwardRef<HTMLInputElement, CustomInput>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask="V:V:V:[[:]V][:][[:]V]"
      blocks={{
        V: {
          mask: /^[0-9A-Fa-f]{0,4}$/,
        }
      }}
      // placeholder='Ex: 192.168.0.2'
      onAccept={(value) => onChange({ target: { value } })}
    // unmask={true}
    />
  );
});

export const IpMaskMaskInput = React.forwardRef<HTMLInputElement, CustomInput>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask={'{/}0000'}
      placeholder='Ex: /32'
      onAccept={(value) => onChange({ target: { value } })}
    />
  );
});

export const PortaInput = React.forwardRef<HTMLInputElement, CustomInput>((props, ref) => {
  const { name, onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask={[
        { mask: /^[0-9/-]*$/ }
      ]}
      overwrite
      onAccept={(value) => onChange({ target: { value, name } })}
    />
  );
});

export const CvlanInput = React.forwardRef<HTMLInputElement, CustomInput>((props, ref) => {
  const { name, onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask={Number}
      min={1}
      max={4094}
      onAccept={(value) => onChange({ target: { value, name } })}
    />
  );
});

export const PrefixInput = React.forwardRef<HTMLInputElement, CustomInput>((props, ref) => {
  const { name, onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask={Number}
      min={0}
      max={32}
      onAccept={(value) => onChange({ target: { value, name } })}
    />
  );
});