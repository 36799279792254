import React, { useEffect, useState } from 'react';
import { Certification, CertificationElement, Result } from '../../interfaces/Certification';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, ptBR } from '@mui/x-data-grid';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import handleError from '../../utils/handleError';
import { ScreenItensRequest } from '../../services/apiSys';
import { ScreenItens } from '../../interfaces/ScreenItens';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


type Props = {
  certifications: Certification[];
};

const CertificationsDataGrid = ({ certifications }: Props) => {
  const [selectedResult, setSelectedResult] = useState<{ result: Result, lastUpdt: Date; } | null>(null);
  const [selectedCertification, setSelectedCertification] = useState<CertificationElement[] | null>(null);
  const [mappedCertDetail, setMappedCertDetail] = useState<ReturnType<typeof getMappedCertificationDetail> | null>(null);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [openCertificationModal, setOpenCertificationModal] = useState(false);
  const [screenItens, setScreenItens] = useState<ScreenItens[]>();
  const [loading, setLoading] = useState(false);
  const [loadingCertificationDetails, setLoadingCertificationDetails] = useState(false);
  const [error, setError] = useState("");

  const getScreenItems = async (acesso: string, produto: string) => {
    try {
      setScreenItens(undefined);
      setLoading(true);
      const res = await ScreenItensRequest(acesso, produto);
      setScreenItens(res.data);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  // Function to map the certifications with screenItens
  const getMappedCertificationDetail = (
    certifications: CertificationElement[],
    screenItens: ScreenItens[]
  ) => {
    return certifications.map(cert => {
      const mapeamento = screenItens.find(m => m.id === cert.id);

      if (!mapeamento) return null;

      return {
        ...cert,
        certificationAlias: mapeamento.alias,
        actions: cert.actions.map(action => ({
          ...action,
          alias: mapeamento.actions.find(a => a.id === action.id)?.alias || 'Não encontrado'
        }))
      };
    }).filter(Boolean); // Remove entradas nulas
  };

  // With Screen Itens and a selected Certification, map the certification detail object
  useEffect(() => {
    if (selectedCertification && screenItens) {
      setMappedCertDetail(getMappedCertificationDetail(selectedCertification, screenItens));
    }
  }, [selectedCertification, screenItens]);

  // Finish loading the certification detail mapping
  useEffect(() => {
    setLoadingCertificationDetails(false);
  }, [mappedCertDetail]);

  const columns: GridColDef<Certification>[] = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 2,
      // renderCell: ({ value }) => (
      //   <Link to={`/fenix/historic/${value}`} target="_blank" >{value}</Link>
      // ),
    },
    {
      field: 'created_at',
      headerName: 'Data de execução',
      flex: 2,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    },
    { field: 'circuit_id', headerName: 'ID Circuito', flex: 1 },
    { field: 'login_tecnico', headerName: 'Login do Técnico', flex: 1 },
    { field: 'produto', headerName: 'Produto', flex: 1 },
    { field: 'acesso', headerName: 'Acesso', flex: 1 },
    {
      field: 'result',
      headerName: 'Resultado',
      width: 120,
      renderCell: (params: GridRenderCellParams<Certification>) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setSelectedResult({ result: params.row.result, lastUpdt: params.row.updated_at });
            setOpenResultModal(true);
          }}
        >
          Ver detalhes
        </Button>
      )
    },
    {
      field: 'certifications',
      headerName: 'Certificados',
      width: 120,
      renderCell: (params: GridRenderCellParams<Certification>) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setLoadingCertificationDetails(true);
            getScreenItems(params.row.acesso, params.row.produto);
            setSelectedCertification(params.row.certifications);
            setOpenCertificationModal(true);
          }}
        >
          Ver detalhes
        </Button>
      )
    }
  ];

  return (
    <Box marginTop={2} width={'100%'}>
      <DataGrid
        autoHeight
        rows={certifications}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          // sorting: {
          //   sortModel: [{ field: 'created_at', sort: 'desc' }]
          // }
        }}
        pageSizeOptions={[10, 20, 30]}
        disableColumnSelector
        getRowId={(row) => row._id}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        columnVisibilityModel={{
          _id: false,
        }}
        sx={{ '--DataGrid-overlayHeight': '10em' }}
      />

      {/* RESULT Modal */}
      <Dialog
        open={openResultModal}
        onClose={() => setOpenResultModal(false)}
        fullWidth
      >
        <DialogTitle>
          Detalhes do Resultado
        </DialogTitle>
        <DialogContent>
          {selectedResult && (
            <>
              <Typography><strong>Protocolo:</strong> {selectedResult.result.protocol}</Typography>
              <Typography><strong>Status:</strong> {selectedResult.result.status}</Typography>
              <Typography>
                <strong>Criado em:</strong> {selectedResult.result.created_at ? new Date(selectedResult.result.created_at).toLocaleString("pt-BR") : new Date(selectedResult.lastUpdt).toLocaleString("pt-BR")}
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* CERTIFICATION Modal */}
      <Dialog
        open={openCertificationModal}
        onClose={() => setOpenCertificationModal(false)}
        maxWidth={false}
      >
        <DialogTitle>Detalhes das Certificações</DialogTitle>
        <DialogContent>
          {!loadingCertificationDetails && mappedCertDetail && mappedCertDetail.map((certification, index) => (
            certification && (
              <>
                <Accordion key={index} sx={{ mb: 4 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ bgcolor: certification.status === "failed" ? '#F06D9E' : certification.status === "passed" ? '#65B878' : certification.status === "pending" ? 'lightgray' : '#ffc230' }} >
                    <Typography variant="h6" gutterBottom>
                      {certification.certificationAlias}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>Status: {certification.status}</Typography>
                    <Typography>
                      Executado em: {new Date(certification.executed_at).toLocaleString("pt-BR")}
                    </Typography>
                    <Typography>
                      Vendor: {certification.extra.vendor}
                    </Typography>
                    <Typography>
                      Modelo: {certification.extra.model}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      {certification.actions && certification.actions.map((action, actionIndex) => (
                        <Box key={actionIndex} sx={{ mb: 2, p: 2, bgcolor: action.status === "failed" ? '#F06D9E' : action.status === "passed" ? '#65B878' : action.status === "pending" ? 'lightgray' : '#ffc230' }}>
                          <Typography><strong>{action.alias}</strong></Typography>
                          <Typography>Status: {action.status}</Typography>
                          <Typography>Recomendação: {action.recommendation}</Typography>
                        </Box>
                      ))}
                    </Box>
                    <Divider />
                    {certification.extra && certification.extra.logs && (
                      <>
                        <Typography variant='h6' gutterBottom>
                          Logs
                        </Typography>
                        {certification.extra.logs.map((item, index) => (
                          <Accordion key={index} sx={{ mb: 1 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              {item.function}
                            </AccordionSummary>
                            <AccordionDetails>
                              <pre>{item.log}</pre>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </>
                    )}

                  </AccordionDetails>
                </Accordion>
              </>
            )
          ))}
          {loadingCertificationDetails && (
            <Box display={'flex'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Alert severity='error'>{error}</Alert>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CertificationsDataGrid;